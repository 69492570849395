import React from 'react';
import { Link } from 'gatsby';
import AlreadyAppliedIllustration from './AlreadyAppliedIllustration';

const AlreadyAppliedPage = () => (
	<section className="pb-0 bg-white" style={{ minHeight: '75vh' }}>
		<div className="container">
			<div className="row pt-5">
				<div className="col-md text-center">
					<AlreadyAppliedIllustration />
				</div>
				<div className="col-md mt-4 mt-md-0">
					<h3 className="text-center text-md-left">
						Ezzel az e-mail címmel vagy telefonszámmal már jelentkeztél ViddL
						futárnak
					</h3>
					<p className="text-center text-md-left text-dark">
						Hívj minket, vagy írj nekünk, és megbeszéljük a következő lépéseket!
					</p>
					<div className="row pt-4 text-center text-md-left">
						<div className="col">
							<div className="text-caption text-dark">e-mail cím</div>
							<div className="text-uppercase font-weight-bold">
								info@viddl.hu
							</div>
						</div>
						<div className="col">
							<div className="text-caption text-dark">telefonszám</div>
							<div className="text-uppercase font-weight-bold">
								+36 20 916 6594
							</div>
						</div>
					</div>
					<div className="text-center">
						<Link
							to="/courier"
							className="btn btn-primary mt-4 d-block d-md-inline-block"
						>
							Vissza
						</Link>
					</div>
				</div>
			</div>
		</div>
	</section>
);

export default AlreadyAppliedPage;
