import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { ErrorPage } from '../components/common/ErrorPage';
import Layout from '../components/layout/Layout';
import AlreadyAppliedPage from '../components/common/AlreadyAppliedPage';

const displayMessages = {
	default: 'A keresett oldal nem található',
	tokenExpired: 'Ez a link lejárt',
	emailAlreadyVerified: 'Ez az e-mail cím már meg lett erősítve',
	pickupPoint:
		'Sajnos már nincsen lehetőséged módosításra. Ha holnap is megkíséreljük csomagod kiszállítását, akkor egy új e-mailt fogsz kapni tőlünk',
};

const Error = ({ location, data: { logo_footer, logo_eit_footer } }) => {
	const type = location.state?.type ?? 'default';
	const message = displayMessages[type];

	const displayPage = () => {
		if (type === 'already-applied') {
			return <AlreadyAppliedPage />;
		}

		return <ErrorPage message={message} />;
	};

	return (
		<Layout footerImg={logo_footer} footerEitImg={logo_eit_footer}>
			{displayPage()}
		</Layout>
	);
};

Error.propTypes = {
	/* eslint-disable-next-line react/forbid-prop-types */
	data: PropTypes.object.isRequired,
	location: PropTypes.shape({
		state: PropTypes.shape({
			type: PropTypes.oneOf(Object.keys(displayMessages)),
		}),
	}).isRequired,
};

export const query = graphql`
	{
		logo_footer: file(relativePath: { eq: "logo_footer.png" }) {
			childImageSharp {
				fixed(width: 92, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		logo_eit_footer: file(relativePath: { eq: "eit.png" }) {
			childImageSharp {
				fixed(width: 200, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
	}
`;

export default Error;
